import ROLES from "$/settings/roles-permissions.json";

export const getAllRoles = (
  {
    getOEM,
    getCRM,
    getFacility,
  }: {
    getOEM: boolean;
    getCRM: boolean;
    getFacility: boolean;
  } = {
    getOEM: true,
    getCRM: true,
    getFacility: true,
  },
): {
  [key: string]: {
    RANK: number;
    VALUE: string;
    ROLE_SCOPE: string;
  };
} => {
  return ROLES.USERS.reduce((obj, type, index) => {
    const roleScope = Object.values(type)[0].ROLE_SCOPE;
    return {
      ...obj,
      ...((roleScope === "OEM"
        ? getOEM
        : roleScope === "CRM"
        ? getCRM
        : getFacility) && {
        [Object.keys(type)[0]]: {
          RANK: index * 2,
          VALUE: Object.keys(type)[0],
          ROLE_SCOPE: Object.values(type)[0].ROLE_SCOPE,
        },
      }),
    };
  }, {});
};
